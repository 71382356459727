import React from 'react';
import Container from './../components/bulma/container';
import Hero from './../components/bulma/hero';
import Navbar from './../components/bulma/navbar';
import Heading from '../components/bulma/heading';
import logo from '../assets/favicon-32x32.png';

class Header extends React.PureComponent {
  state = {
    active: false
  }

  toggleMenu = () => {
    this.setState({
      active: !this.state.active
    });
  }

  closeMenu = () => {
    this.setState({
      active: false
    });
  }

  render() {
    const { active } = this.state;

    return (
      <Hero color='primary'>
        <Hero.Part part='head'>
          <Navbar active={active}>
            <Container>
              <Navbar.Brand>
                <Navbar.Item>
                  <img src={logo} alt='MetyWeb Logo' />
                  MetyWeb
                </Navbar.Item>

                <Navbar.Burger title='Toon/verberg menu' onClick={this.toggleMenu} />
              </Navbar.Brand>
              <Navbar.Menu>
                <Navbar.Container>
                  <Navbar.Item
                    onClick={this.closeMenu}
                  >
                    Afgelopen 24 uur
                  </Navbar.Item>
                </Navbar.Container>
              </Navbar.Menu>
            </Container>
          </Navbar>
        </Hero.Part>
        <Hero.Part part='body'>
          <Container>
            <Heading>MetyWeb</Heading>
            <Heading renderAs='h2' subtitle>Weergegevens rechtstreeks oet Ranzel</Heading>
          </Container>
        </Hero.Part>
      </Hero>
    );
  }
}

export default Header;