import React from 'react';
import Header from './layout/Header';
import Home from './pages/home/Home';

const Layout = () => (
  <>
    <Header />
    <Home />
  </>
)

export default Layout;

