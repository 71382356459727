import React from 'react';
import classNames from 'classnames';
import MeasurementsCache from './MeasurementsCache';
import './Home.scss';

import FormatTime from '../../utils/FormatTime';
import Page from '../../components/Page';
import Section from '../../components/bulma/section';
import Container from '../../components/bulma/container';
import Table from '../../components/bulma/table';
import Level from '../../components/bulma/level';
import Heading from '../../components/bulma/heading';
import Tag from '../../components/bulma/tag';
import Timer from '../../components/Timer';
import ToTitle from '../../components/ToTitle';

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock.src'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faThermometerFull,
  faThermometerHalf,
  faThermometerEmpty,
  faTint
} from '@fortawesome/free-solid-svg-icons';

class Home extends Page {
  static pageTitle = 'Afgelopen 24 uur';

  state = {
    loading: true,
    measurements: [],
    lastUpdate: null,
    lastUpdateError: false
  };

  onMeasurements = ({ detail: data }) => {
    const measurements = [];

    // Convert data object to array and apply normalizations.
    for (const key in data) {
      const measurement = data[key];
      measurement.time = new Date(measurement.time);
      measurements.push(measurement);
    }

    // Sort measurements in descending order.
    measurements.sort((a, b) => b.time.getTime() - a.time.getTime());

    this.setState({
      loading: false,
      topData: Home.getTopData(measurements),
      measurements,
      lastUpdate: new Date(),
    });
  }

  componentDidMount() {
    this.cache = new MeasurementsCache('measurements', 86400000);
    this.cache.addEventListener('value', this.onMeasurements);
  }

  componentWillUnmount() {
    this.cache.removeEventListener('value', this.onMeasurements);
  }

  static formatTemp(num) {
    return `${num.toLocaleString(navigator.language, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1
    })} °C`;
  }

  static formatHourMinute(date) {
    return date.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  static getTopData(measurements) {
    const topData = {
      // Min and max need to be values that will never be reached.
      minTemp: 100,
      minTempTime: '',

      maxTemp: -100,
      maxTempTime: '',

      avgTemp: 0,
      sumRain: 0,
    };
  
    if (measurements.length !== 0) {
      // The measurements are sorted in a descending order, so the newest record
      // will be at index 0.
      const lastDate = measurements[0].time.getDate();

      let sumTemp = 0;
      let countTemp = 0;
      for (let i = 0; i < measurements.length; i++) {
        const measurement = measurements[i];
        if (measurement.time.getDate() !== lastDate) continue;

        if (measurement.temperature < topData.minTemp) {
          topData.minTemp = measurement.temperature;
          topData.minTempTime = measurement.time;
        }
        if (measurement.temperature > topData.maxTemp) {
          topData.maxTemp = measurement.temperature;
          topData.maxTempTime = measurement.time;
        }

        sumTemp += measurement.temperature;
        countTemp++;
        topData.sumRain += measurement.rain || 0;
      }

      topData.avgTemp = sumTemp / countTemp;
    }

    return topData;
  }

  render() {
    const {
      loading,
      measurements,
      topData,
      lastUpdate,
      lastUpdateError
    } = this.state;

    if (loading) {
      return (
        <Section>
          <Container className='loader-wrapper'>
            <div className='loader'></div>
          </Container>
        </Section>
      );
    }

    return (
      <>
        <Section>
          <Container>
            <Tag
              style={{
                marginBottom: '0.75em'
              }}
              color={lastUpdateError ? 'danger' : 'dark'}
              className='is-pulled-right'
            >
              {lastUpdateError && 'Bijwerken mislukt'}
              {!lastUpdateError &&
                <>
                  Bijgewerkt: <Timer
                    time={lastUpdate}
                    updateInterval={5000}
                  />
                </>
              }
            </Tag>
          </Container>
          <Container style={{ clear: 'both' }}>
            {measurements.length !== 0 &&
              <>
                <Heading renderAs='h4' size={5}>{
                  ToTitle(measurements[0].time.toLocaleDateString(navigator.language, {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long'
                  }))
                }
                </Heading>
                <Level>
                  <Level.Item className='has-text-centered'>
                    <div>
                      <p className='heading'>Gem. temperatuur</p>
                      <p className='title'>
                        <FontAwesomeIcon icon={faThermometerHalf} />
                        &nbsp;{Home.formatTemp(topData.avgTemp)}
                      </p>
                    </div>
                  </Level.Item>
                  <Level.Item className='has-text-centered'>
                    <div>
                      <p className='heading'>Max. temperatuur</p>
                      <p className='title'>
                        <FontAwesomeIcon icon={faThermometerFull} className='is-text-danger' />
                        &nbsp;{Home.formatTemp(topData.maxTemp)}
                      </p>
                      <small>Gemeten om {Home.formatHourMinute(topData.maxTempTime)}</small>
                    </div>
                  </Level.Item>
                  <Level.Item className='has-text-centered'>
                    <div>
                      <p className='heading'>Min. temperatuur</p>
                      <p className='title'>
                        <FontAwesomeIcon icon={faThermometerEmpty} className='is-text-info' />
                        &nbsp;{Home.formatTemp(topData.minTemp)}
                      </p>
                      <small>Gemeten om {Home.formatHourMinute(topData.minTempTime)}</small>
                    </div>
                  </Level.Item>
                  <Level.Item className='has-text-centered'>
                    <div>
                      <p className='heading'>Neerslag</p>
                      <p className='title'>
                        <FontAwesomeIcon icon={faTint} />
                        &nbsp;{topData.sumRain.toLocaleString(navigator.language)} mm
                      </p>
                    </div>
                  </Level.Item>
                </Level>
              </>
            }
          </Container>
        </Section>
        { measurements.length !== 0 &&
          <Section>
            <Container>
              <HighchartsReact
                highcharts={Highcharts}
                constructorType={'stockChart'}
                updateArgs={[true, false, true]}
                options={{
                  accessibility: false,
                  title: {
                    text: 'Temperatuur & neerslag',
                    style: {
                      color: '#fff',
                      fontSize: '1.25rem',
                      fontWeight: 300,
                      fontFamily: 'inherit'
                    }
                  },
                  credits: {
                    enabled: false
                  },
                  rangeSelector: {
                    labelStyle: {
                      color: '#fff'
                    },
                    allButtonsEnabled: true,
                    buttonPosition: {
                      align: 'right',
                    },
                    buttons: [{
                      type: 'hour',
                      count: 2,
                      text: '2h'
                    }, {
                      type: 'hour',
                      count: 6,
                      text: '6h'
                    }, {
                      type: 'hour',
                      count: 12,
                      text: '12h'
                    }, {
                      type: 'all',
                      text: 'Alle'
                    }],
                    selected: 0,
                    inputEnabled: false
                  },
                  tooltip: {
                    split: false,
                    shared: true,
                    animation: false,
                    pointFormatter: function() {
                      const { name } = this.series;
                      let unit = '';
                      if (name === 'Temperatuur') {
                        unit = '°C';
                      }
                      if (name === 'Neerslag') {
                        unit = 'mm';
                      }
                      return `<span style="color:${this.color}">\u25CF</span> ${name}: <b>${this.y.toLocaleString(navigator.language)} ${unit}</b><br/>`;
                    },
                  },
                  xAxis: {
                    labels: {
                      style: {
                        color: '#fff'
                      }
                    },
                    type: 'datetime',
                    crosshair: {
                      snap: false
                    }
                  },
                  yAxis: [{
                    labels: {
                      formatter: function() {
                        return this.value.toLocaleString(navigator.language) + ' °C';
                      },
                      style: {
                        color: '#fff'
                      }
                    },
                  }, {
                    labels: {
                      enabled: false
                    }
                  }],
                  chart: {
                    zoomType: 'x',
                    backgroundColor: 'transparent',
                    spacingRight: 0,
                    spacingLeft: 0,
                  },
                  navigator: {
                    enabled: true
                  },
                  time: {
                    useUTC: false,
                  },
                  series: [{
                    name: 'Temperatuur',
                    data: measurements.map(m => [m.time.getTime(), m.temperature]).sort((a, b) => a[0] - b[0]),
                    color: '#23D160',
                    yAxis: 0,
                    lineWidth: 2
                  },
                  {
                    name: 'Neerslag',
                    type: 'column',
                    dataLabels: {
                      enabled: true,
                      style: {
                        color: '#fff',
                        fontSize: '0.75rem',
                        textOutline: 'unset',
                        fontWeight: 'normal',
                      },
                      formatter: function() {
                        return this.y.toLocaleString();
                      },
                      filter: {
                        property: 'y',
                        operator: '>',
                        value: 0
                      }
                    },
                    data: measurements.map(m => [m.time.getTime(), m.rain]).sort((a, b) => a[0] - b[0]),
                    color: '#3273DC',
                    yAxis: 1
                  }],
                }}
              />
            </Container>
          </Section>
        }
        <Section id='weather-section'>
          <Container style={{ overflowX: 'auto' }}>
            <Table hoverable>
              <thead>
                <tr>
                  <th>Tijd</th>
                  <th>Temperatuur</th>
                  <th>Neerslag</th>
                  <th>Bladnat</th>
                  <th>Luchtvochtigheid</th>
                </tr>
              </thead>
              <tbody>
                {measurements.map(measurement => (
                  <tr key={measurement.time}>
                    <td>{FormatTime(measurement.time)}</td>
                    <td>{Home.formatTemp(measurement.temperature)}</td>
                    <td className={classNames({
                      'is-selected': measurement.rain > 0
                    })}>{measurement.rain.toLocaleString(navigator.language)} mm</td>
                    <td>{measurement.wettness}%</td>
                    <td>{measurement.humidity}%</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
        </Section>
      </>
    );
  }
}

export default Home;