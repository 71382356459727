const constants = {
  BREAKPOINTS: [
    'desktop',
    'tablet',
    'mobile',
    'widescreen',
    'fullhd',
    'touch'
  ],
  COLORS: [
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'light',
    'dark'
  ]
};

export default constants;